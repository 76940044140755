import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

//Funksjon som setter visibility:hidden på elementet den brukes på. Gjør at skjermlesere ikke "ser" elementer
export function withVisibilityHidden(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                style={{ ...props.style, visibility: "hidden" }}
                aria-hidden="true"
            />
        )
    }
}

//Funksjon som setter aria:hidden på elementet den brukes på.
export function withAriaHidden(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                style={{ ...props.style }}
                aria-hidden="true"
            />
        )
    }
}

//Funksjon som setter tab-index: -1 på elementet den brukes på. Tar vekk tabindex:1 som framer putter på alle komponenter av en eller annen grunn...
export function withNoTabIndex(Component): ComponentType {
    return (props) => {
        return <Component {...props} style={{ ...props.style }} tabIndex="-1" />
    }
}
